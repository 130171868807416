import { gql } from "@apollo/client"

const GET_CART = gql`
  query GET_CART {
    cart {
      isEmpty
      availableShippingMethods {
        rates {
          methodId
          label
          instanceId
          id
          cost
        }
      }
      chosenShippingMethods
      appliedCoupons {
        code
        discountAmount
        discountTax
      }
      contents {
        nodes {
          key
          product {
            node {
              id
              databaseId
              name
              type
              onSale
              slug
              image {
                id
                sourceUrl
                srcSet
                altText
                title
              }
              productCategories {
                nodes {
                  id
                  name
                }
              }
            }
            simpleVariations {
              name
              value
            }
          }
          quantity
          total
          subtotal
          subtotalTax
          extraDataItem {
            variation
            brightness
            custom_price
            width
            height
            pdf
            rotate_horizontal
            rotate_vertical
            saturation
            scale
            texture
            x
            y
            hue
          }
        }
      }
      subtotal
      subtotalTax
      shippingTax
      shippingTotal
      total
      totalTax
      feeTax
      feeTotal
      discountTax
      discountTotal
    }
    paymentGateways {
      nodes {
        icon
        description
        id
        title
      }
    }
  }
`

export default GET_CART
