import React from "react"
import { useMutation } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import { Item } from "./components"

import UPDATE_CART from "mutations/update-cart"
import CLEAR_CART from "mutations/clear-cart"

import IcoTrash from "assets/icons/trash.svg"

const Table = ({ cart, setCart, refetch, readOnly }) => {
  // Update Cart Mutation
  const [updateCart] = useMutation(UPDATE_CART, {
    onCompleted: () => {
      toast.success("Koszyk został zaktualizowany.")
      refetch()
    },
    onError: error => {
      if (error) {
        console.warn(error.graphQLErrors[0].messagee)
      }
    },
  })

  // Clear Cart Mutation
  const [clearCart] = useMutation(CLEAR_CART, {
    onCompleted: () => {
      toast.warning("Produkty z koszyka zostały usunięte.")
      refetch()
    },
    onError: error => {
      console.log(error)
      console.warn(error.graphQLErrors[0].messagee)
    },
  })

  return (
    <>
      <div className="cart-content__header">
        <h5>Produkty</h5>
      </div>
      <div className="cart-content__table">
        <div className="cart-content__table-header">
          <strong>Nazwa</strong>
          <strong>Liczba sztuk</strong>
          <strong>Wartość (brutto)</strong>
          {!readOnly && <strong>Usuń</strong>}
        </div>

        <div className="cart-content__table-content">
          {cart &&
            cart?.products?.map((item, index) => (
              <Item
                item={item}
                readOnly={readOnly}
                updateCart={updateCart}
                key={index}
                cart={cart}
                refetch={refetch}
              />
            ))}
        </div>

        {!readOnly && (
          <div className="cart-content__remove-all">
            <button
              onClick={() =>
                clearCart({
                  variables: {
                    input: {
                      all: true,
                      clientMutationId: v4(),
                    },
                  },
                })
              }
            >
              <img src={IcoTrash} alt="" /> Usuń wszystkie produkty
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default Table
