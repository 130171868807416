/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState, useEffect } from "react"
import { v4 } from "uuid"
import { NumericFormat } from "react-number-format"
import axios from "axios"

import { useProducts } from "context/products"

import { getUpdatedItems, getUpdatedItemsExtraData } from "utils/functions"

import IcoMinus from "assets/icons/quantity-minus.png"
import IcoPlus from "assets/icons/quantity-plus.svg"
import IcoTrash from "assets/icons/trash.svg"

const Item = ({ cart, refetch, item, readOnly, updateCart }) => {
  const variation_id = item?.variation[0]?.value
  const [product_variations, setProductVariations] = useState(null)

  const { products } = useProducts()
  let is_outlet = item?.productCategories?.nodes?.some(
    node => node?.name === "Outlet"
  )

  // Handle remove product
  const handleRemoveProductClick = (event, cartKey, products) => {
    event.stopPropagation()
    if (products.length) {
      const newQty = 0
      const updatedItems = getUpdatedItems(products, newQty, cartKey)
      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      })
    }
    refetch()
  }

  // Handle update product quantity
  const handleUpdateProductQuantity = (
    event,
    keyToUpdate,
    cartKey,
    products,
    newData,
    isExtraData
  ) => {
    event.stopPropagation()
    if (products.length) {
      const updatedItems = !!!isExtraData
        ? getUpdatedItems(products, newData, cartKey)
        : getUpdatedItemsExtraData(products, keyToUpdate, newData, cartKey)

      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      })
    }
    refetch()
  }

  const selected_texture = products
    ?.find(element => element?.databaseId === item?.productId)
    ?.acfProduct?.textures?.find(
      element => element?.databaseId === parseInt(item?.extraDataItem?.texture)
    )

  let extras = []
  if (item?.variation[0]?.value) {
    extras?.push(`wariant: ${item?.variation[0]?.value}`)
  }
  if (!!item?.extraDataItem?.variation) {
    extras?.push(`wariant: ${item?.extraDataItem?.variation}`)
  }
  if (!!selected_texture) {
    extras?.push(`tekstura: ${selected_texture?.title}`)
  }
  if (!!item?.extraDataItem?.height) {
    extras?.push(
      `wymiary: ${item?.extraDataItem?.width}x${item?.extraDataItem?.height}`
    )
  }
  if (!!item?.extraDataItem?.scale) {
    extras?.push(`skala: ${item?.extraDataItem?.scale}%`)
  }
  if (!!item?.extraDataItem?.saturation) {
    extras?.push(`nasycenie: ${item?.extraDataItem?.saturation}`)
  }
  if (!!item?.extraDataItem?.brightness) {
    extras?.push(`jasność: ${item?.extraDataItem?.brightness}`)
  }
  if (!!item?.extraDataItem?.hue) {
    extras?.push(`barwa: ${item?.extraDataItem?.hue}`)
  }

  useEffect(() => {
    ;(async () => {
      await getProductVariation()
    })()
  }, [])

  const getProductVariation = async () => {
    try {
      const variations = await axios?.get(
        `https://cms.wallcraft.com.pl/wp-json/wc/v3/products/${item?.productId}/variations?consumer_key=ck_6308078b67bd575fd2cb12f92e564622d4fe3938&consumer_secret=cs_405c5f1c93af27c9954a41bcacc2eb33023684e3`
      )

      setProductVariations([
        ...variations?.data
          ?.reverse()
          ?.filter(item => item?.attributes[0].option === variation_id)
          .map(item => ({
            id: item?.id,
            name: item?.attributes[0].option,
            price: item?.price,
            image: item?.image?.src,
          })),
      ])
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="cart-content__item">
      <div className="cart-content__item-col">
        <div className="cart-content__item-inner">
          <div
            className="cart-content__item-image"
            style={{
              backgroundImage: `url('${
                product_variations?.length > 0
                  ? product_variations[0]?.image
                  : item?.image?.sourceUrl
              }')`,
            }}
          />
          <p>
            {item.name} {extras?.length > 0 ? `(${extras?.join(", ")})` : null}
          </p>
        </div>
      </div>
      <div className="cart-content__item-col">
        <small>Liczba sztuk</small>
        <div className="product-buy__quantity">
          {!readOnly && !is_outlet && (
            <button
              onClick={event => {
                handleUpdateProductQuantity(
                  event,
                  null,
                  item.cartKey,
                  cart.products,
                  parseInt(item.qty - 1)
                )
              }}
            >
              <img src={IcoMinus} alt="" />
            </button>
          )}
          <div className={readOnly ? "read-only" : ""}>{item.qty}</div>
          {!readOnly && !is_outlet && (
            <button
              onClick={event => {
                handleUpdateProductQuantity(
                  event,
                  null,
                  item.cartKey,
                  cart.products,
                  parseInt(item.qty + 1)
                )
              }}
            >
              <img src={IcoPlus} alt="" />
            </button>
          )}
        </div>
      </div>
      {/* <div className="cart-content__item-col">
        <small>Czas realizacji</small>
        <div className="cart-content__item-time">
          <Checkbox
            onChange={event =>
              handleUpdateProductQuantity(
                event,
                "processing_time",
                item.cartKey,
                cart.products,
                "standard",
                true
              )
            }
            name="processing_time"
          >
            Ekspres (3 dni robocze)
          </Checkbox>
          <Checkbox
            onChange={event =>
              handleUpdateProductQuantity(
                event,
                "processing_time",
                item.cartKey,
                cart.products,
                "express",
                true
              )
            }
            name="processing_time"
          >
            Standard (7 - 10 dni roboczych)
          </Checkbox>
        </div>
      </div> */}
      <div className="cart-content__item-col">
        <small>Wartość (brutto)</small>
        <div className="cart-content__item-price">
          <NumericFormat
            value={item.totalPrice?.replace(/,/g, "")}
            displayType={"text"}
            thousandSeparator={" "}
            decimalScale={0}
            suffix={" zł"}
          />
        </div>
      </div>
      {!readOnly && (
        <div className="cart-content__item-col">
          <small>Usuń</small>
          <button
            className="cart-content__item-remove"
            onClick={event => {
              handleRemoveProductClick(event, item.cartKey, cart.products)
            }}
          >
            <img src={IcoTrash} alt="" />
          </button>
        </div>
      )}
    </div>
  )
}

export default Item
